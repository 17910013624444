import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer, Polygon, Popup, ZoomControl } from "react-leaflet";
import { polygonToCells, cellToBoundary } from "h3-js";
import "leaflet/dist/leaflet.css";
import NetworkService from "./NetworkService";
import colors from "./colors.json";

const RIDE_REQUEST_SCALE = 2;

const App = () => {
  const center = [44.80317492488992, 20.45098200650307];
  const resolution = 8;

  const polygon = [
    [44.887614421661624, 20.24720205020921],
    [44.932117033757834, 20.36210517093894],
    [44.887170113212335, 20.47502276177314],
    [44.93144954052603, 20.590075314036145],
    [44.88627261672047, 20.702892516207516],
    [44.796864045165975, 20.700525642136274],
    [44.75263850638808, 20.585589204520407],
    [44.663108391963675, 20.58335344417042],
    [44.618714247002856, 20.468678822733505],
    [44.663796253689476, 20.356125352915107],
    [44.753438357252875, 20.35818099975586],
    [44.79821939369944, 20.245330407781925],
    [44.887614421661624, 20.24720205020921],
  ];

  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    const fetchZonesData = () => {
      NetworkService.getZonesData()
        .then((zones) => {
          setMapData(zones);
        })
        .catch((error) => {
          console.error("Error fetching zones data:", error);
        });
    };
    fetchZonesData();
    const intervalId = setInterval(fetchZonesData, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const hexagonToPolygon = (hex) => {
    const boundary = cellToBoundary(hex, true);
    return boundary.map(([lat, lng]) => [lng, lat]);
  };

  const polygons = useMemo(() => {
    if (!mapData) {
      return [];
    }
    const hexagons = polygonToCells([polygon], resolution);
    return hexagons.map((hex) => {
      const hexagonData = mapData.find((h) => h.h3_hash === hex);
      if (!hexagonData) {
        return null;
      }
      const requests = Math.round(hexagonData?.request_count / RIDE_REQUEST_SCALE);
      const color = colors[Math.min(requests, 20)] || "transparent";
      return <Polygon key={hex} positions={hexagonToPolygon(hex)} fillColor={color} weight={0} fillOpacity={0.5} />;
    });
  }, [mapData, resolution]);

  return (
    <MapContainer
      attributionControl={false}
      zoomControl={false}
      center={center}
      zoom={12}
      style={{ height: "100vh", width: "100%" }}
      maxZoom={14}
      minZoom={11}>
      <TileLayer attribution="CAR:GO" url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      {polygons}
    </MapContainer>
  );
};

export default App;

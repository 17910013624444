import axios from "axios";

export class NetworkService {
  constructor() {
    this.url = process.env.REACT_APP_API_URL || "https://api.appcargo.com";
    this._handleErrors = this._handleErrors.bind(this);
    this._handleResponse = this._handleResponse.bind(this);
  }

  async _makeRequest(method, path, config = {}) {
    const { headers, body, params, ...rest } = config;
    let requestHeaders = {
      ...headers,
    };

    if ((method === "POST" || method === "PUT") && body && !(body instanceof FormData)) {
      requestHeaders["Content-Type"] = "application/json";
    }

    try {
      const response = await axios({
        method,
        url: `${this.url}/${path}`,
        data: body,
        headers: requestHeaders,
        params,
        ...rest,
      });
      return this._handleResponse(response);
    } catch (error) {
      this._handleErrors(error);
    }
  }

  _handleErrors(error) {
    if (!error.response) {
      console.error("Network error:", error);
      throw new Error("Network error");
    }
    const { status } = error.response;
    if (status === 401) {
      localStorage.setItem("user", null);
      window.location.reload();
    } else if (status === 403) {
      console.error("Forbidden access:", error.response);
      if (this.handle403) this.handle403(error.response);
    } else {
      console.error("API error:", error.response);
    }
    throw error.response;
  }

  async _handleResponse(response) {
    const contentType = response.headers["content-type"];
    if (contentType && contentType.includes("application/json")) {
      return response.data;
    }
    return response;
  }

  async getZonesData() {
    return this._makeRequest("GET", `h3/heatmap-data`, {});
  }
}

export default new NetworkService();
